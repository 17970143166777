import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {headersAccept, headersAuthorization, headersContent} from "./APIToken";

export const chartApi = createApi({
    reducerPath: 'chartApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_URL}/user/forex`,
        prepareHeaders: (headers, {getState}) => {
            headers.set("Content-type", headersContent)
            headers.set("Accept", headersAccept)
            headers.set("Authorization", headersAuthorization)
        },
    }),
    tagTypes: ['settingsChart'],
    endpoints: (build) => ({
        getChartBalance: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/balance/${url}`
            }),
            keepUnusedDataFor: 0,
        }),

        getChartBalanceGain: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/balance-gain/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartTraders: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/traders/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartDrawdown: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/dropdown/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartDrawdownAndGain: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/balance-with-dropdown/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartSymbol: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/symbol-profit-losing-ratio/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartDay: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/days-profit-losing-ratio/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartHours: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/hours-profit-losing-ratio/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartMonths: build.query({
            query: ({id, url}) => ({
                url: `/${id}/graph/months/${url}`
            }),
            keepUnusedDataFor: 0,
        }),
        getChartSpeculative: build.query({
            query: ({timeline, tools}) => ({
                url: `${process.env.REACT_APP_URL}/stock/quotes/${tools}/${timeline}`
            }),
            transformResponse(baseQueryReturnValue: any) {
                console.log(baseQueryReturnValue)
                return {
                    ...baseQueryReturnValue, data: baseQueryReturnValue?.data?.map((el: any) => {
                        return {
                            ...el,
                            Date: el.t ? new Date(el.t)?.valueOf() : 0,
                            Open: +el.o,
                            High: +el.h,
                            Low: +el.l,
                            Close: +el.c,
                            Volume: +el.h
                        }
                    })
                }
            },
            keepUnusedDataFor: 0,
        }),
        getStockOrBoundsData: build.mutation({
            query: ({type = 1, page = 1, tag, search, searchBySymbol, sort, duration, serachV, searchFieldsV}) => {
                let tagToUrl = tag?.id ? '/' + tag?.slug : '';
                let url = type === 1 ? `${process.env.REACT_APP_URL}/stock/stocks${tagToUrl}` : `${process.env.REACT_APP_URL}/stock/bonds${tagToUrl}?searchJoin=and`
                // ?search=price:100,101;summary_profit:20,23&searchFields=price:between;summary_profit:between
                // ?search=price:100;&searchFields=price:<
                // ?search=price:100;summary_profit:50&searchFields=price:>;summary_profit:<;&searchJoin=and
                console.log(searchBySymbol)
                const params = {
                    searchFields: searchFieldsV,
                    search: serachV,
                    page: page,
                    sortedBy: sort?.sort === 2 ? 'desc' : 'asc',
                    orderBy: sort?.path,
                    duration: duration?.value,
                }
                if (!serachV) {
                    delete params.search
                }
                if (!searchFieldsV) {
                    delete params.searchFields
                }

                if (!sort.path || sort.sort === 1) {
                    //@ts-ignore
                    delete params.sortedBy
                }

                if (!sort.path || sort.sort === 1) {
                    delete params.orderBy
                }
                if ((!duration || duration?.value === 'all') || type === 1) {
                    //@ts-ignore
                    delete params.duration
                }

                if (searchBySymbol) {
                    params.search = serachV ? `${serachV}search:${searchBySymbol};` : `search:${searchBySymbol};`
                }
// debugger
                return {
                    url: url,
                    params: params,
                }
            },
            // keepUnusedDataFor: 0,
        }),
        getStockOrBoundsTags: build.query({
            query: ({type = 1}) => {
                const url = type === 1 ? `${process.env.REACT_APP_URL}/stock/tags/stock` : `${process.env.REACT_APP_URL}/stock/tags/bond`
                console.log(type)
                return {
                    url: url,
                }
            },
            // keepUnusedDataFor: 0,
        }),
        getSettingsChart: build.query({
            query: ({idProduct, idInstrument}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idInstrument}/speculate/settings`
                }
            },
            providesTags: ['settingsChart'],
            // keepUnusedDataFor: 0,
        }),
        createSettings: build.mutation({
            query: ({idProduct, idInstrument, body}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idInstrument}/speculate/settings`,
                    body: body,
                    method: 'POST'
                }
            },
            invalidatesTags: ['settingsChart']
            // keepUnusedDataFor: 0,
        }),
        updateSettings: build.mutation({
            query: ({idProduct, idInstrument, body}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idInstrument}/speculate/settings`,
                    body: body,
                    method: 'PUT'
                }
            },
            invalidatesTags: ['settingsChart']
            // keepUnusedDataFor: 0,
        }),
        setSpeculativeLimit: build.mutation({
            query: ({idProduct, limit, use_speculate_credit}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/speculate/limit`,
                    body: {limit: limit, use_speculate_credit: use_speculate_credit},
                    method: 'PATCH'
                }
            },
        }),
        getCalcSpeculative: build.query({
            query: ({idProduct, idInstrument}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idInstrument}/calc`,
                    method: 'POST'
                }
            },
        }),
        deleteSettingChart: build.mutation({
            query: ({idProduct, idInstrument}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idInstrument}/speculate/settings`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['settingsChart']
        }),
        changeSettingStatus: build.mutation({
            query: ({idProduct, idInstrument, status}) => {
                //disable
                //enable
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idInstrument}/speculate/settings/${status}`,
                    method: 'PATCH'
                }
            },
            invalidatesTags: ['settingsChart']
        }),

        getAllSpeculate: build.query({
            query: ({idProduct, page = 1, sort}) => {
                const params = {
                    sortedBy: sort?.sort === 2 ? 'desc' : 'asc',
                    orderBy: sort?.path,
                }
                if (!sort.path || sort.sort === 1) {
                    //@ts-ignore
                    delete params.sortedBy
                }

                if (!sort.path || sort.sort === 1) {
                    delete params.orderBy
                }

                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/speculate/settings?page=${page}`,
                    method: 'GET',
                    params: params
                }
            },
            providesTags: ['settingsChart'],
            // invalidatesTags: ['settingsChart']
        }),

        getCurrentSymbol: build.mutation({
            query: ({idSymbol}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/symbol/${idSymbol}`,
                    method: 'GET'
                }
            },
            // providesTags: ['settingsChart'],
            // invalidatesTags: ['settingsChart']
        }),
        getDraw: build.mutation({
            query: ({idProduct, idSymbol}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idSymbol}/draw`,
                    method: 'GET'
                }
            },
        }),
        setDraw: build.mutation({
            query: ({idProduct, idSymbol, data}) => {
                return {
                    url: `${process.env.REACT_APP_URL}/stock/product/${idProduct}/symbol/${idSymbol}/draw`,
                    method: 'PUT',
                    body: {draw: JSON.stringify(data)}
                }
            },
        }),

    })
});

export const {
    useGetChartBalanceQuery,
    useGetDrawMutation,
    useSetDrawMutation,
    useGetCurrentSymbolMutation,
    useGetAllSpeculateQuery,
    useDeleteSettingChartMutation,
    useChangeSettingStatusMutation,
    useGetCalcSpeculativeQuery,
    useCreateSettingsMutation,
    useSetSpeculativeLimitMutation,
    useUpdateSettingsMutation,
    useGetSettingsChartQuery,
    useGetStockOrBoundsTagsQuery,
    useGetStockOrBoundsDataMutation,
    useGetChartTradersQuery,
    useGetChartBalanceGainQuery,
    useGetChartSpeculativeQuery,
    useGetChartSymbolQuery,
    useGetChartDayQuery,
    useGetChartHoursQuery,
    useGetChartDrawdownQuery,
    useGetChartDrawdownAndGainQuery,
    useGetChartMonthsQuery,
} = chartApi;

export const {} = chartApi.endpoints;
