import React, {useState} from 'react';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import s from './styles.module.css'
import classNames from "classnames";
import {Switch} from "@mui/material";
import {toast} from "react-toastify";
import {useChangeSettingStatusMutation} from "../../../../../../store/API/chartApi";
import {useSearchParams} from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";

const headers = [
    {
        title: 'Инструмент',
    }, {
        title: 'До пог.',
        path: 'maturity_date',
        defSort: 1
    }, {
        title: 'Цена',
        path: 'price',
        defSort: 1
    }, {
        title: 'Затраты',
        path: 'coast',
        defSort: 1
    }, {
        title: 'Рейтинг',
        path: 'rating',
        defSort: 1
    }, {
        title: 'Проценты',
        path: 'profit_percent',
        defSort: 1
    },
    {
        title: '-',
    },
]

const rowStyleText = {
    fontFamily: 'Montserrat,sans-serif',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14.63px',
    textAlign: 'center',
    padding: '8px',
    color: '#BDBDBD',
    borderColor: '#3C3C3C !important'
}

const TableBlock = ({
                        data,
                        idCurrentSetting,
                        dashboardID,
                        refetchTable,
                        isFetchingTable,
                        setTools,
                        sort, setSort,
                        handleChangeSettingChart
                    }: any) => {
    const [paramsSearch, setParamsSearch] = useSearchParams()

    const [changeSettingStatus, {isLoading: isLoadingStatus}] = useChangeSettingStatusMutation()

    // const [sort, setSort] = useState<any>({
    //     path: '',
    //     //1 2 3
    //     sort: 1
    // })

    console.log(data)

    const handleChangeStatus = (id: any, status: any) => {
        changeSettingStatus({
            idProduct: dashboardID, idInstrument: id, status: status
        })
            .unwrap()
            .then((res) => {
                console.log(res)
                toast.success(status === 'disable' ? 'Отключено' : 'Включено')
                refetchTable()
            })
            .catch((e) => {
                toast.error('Ошибка')
            })
    }

    const handleSort = (path: any, sort: any) => {
        setSort({
                path: path,
                sort: sort
            }
        )
    }

    console.log(sort)

    return (
        <TableContainer sx={{maxHeight: 'fit-content', border: '1px solid #3C3C3C', background: '#1F1F1F'}}>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            headers.map((item: any, index: any) =>
                                <TableCell onClick={() => {
                                    if (!item.defSort) return
                                    if (item.path !== sort.path) {
                                        handleSort(item?.path, 2)
                                    } else {
                                        handleSort(item?.path, (sort.sort === 1 && 2) || (sort.sort === 2 && 3) || (sort.sort === 3 && 1))
                                    }

                                }} sx={{
                                    ...rowStyleText,
                                    cursor: item.type && 'pointer',
                                    userSelect: 'none'
                                }}
                                           key={index}>
                                    <div
                                        className={classNames(s.header_item, (item?.path === sort?.path && +sort?.sort === 2) && s.header_item_active, (sort?.sort !== 1 && sort?.sort && sort.path === item.path) && s.active_sort)}>
                                        {item?.title}
                                        {item?.path ? (
                                            (item?.path === sort.path && sort.sort === 2 &&
                                                <KeyboardArrowUpIcon/>) ||
                                            (item?.path === sort.path && sort.sort === 3 &&
                                                <KeyboardArrowUpIcon/>) ||
                                            <FilterListIcon/>
                                        ) : null}
                                    </div>
                                </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody sx={{maxHeight: '500px'}}>
                    {data?.map((item: any, index: number) => {
                        const isSameId = idCurrentSetting === (item?.symbol_id ? item?.symbol_id : item?.id)

                        return <TableRow key={index} sx={{
                            transition: '.3s all',
                            background: isSameId ? '#2A3336 !important' : 'inherit',
                            '&:hover': {
                                backgroundColor: '#181818'
                            }
                        }}>

                            <TableCell
                                onClick={() => {
                                    paramsSearch.set('symbolID', item?.symbol_id ? item?.symbol_id : item?.id)
                                    setParamsSearch(paramsSearch)
                                    handleChangeSettingChart((item?.symbol_id ? item?.symbol_id : item?.id), item?.title, item.description)
                                    setTools({
                                        symbol: item?.title,
                                        id: (item?.symbol_id ? item?.symbol_id : item?.id),
                                        description: item.description,
                                        time_frame: item?.time_frame,
                                    })
                                }}
                                sx={{
                                    minWidth: '195px',
                                    ...rowStyleText,
                                    textAlign: 'left', cursor: 'pointer',
                                    transition: '.3s all',
                                }}>
                                {`(${item?.title})-${item.description}`}
                            </TableCell>

                            <TableCell sx={{minWidth: '115px', ...rowStyleText}}>
                                {item?.maturity_date || '-'}
                            </TableCell>

                            <TableCell sx={{
                                minWidth: '80px', ...rowStyleText,
                                background: !isSameId && '#29312C' || '',
                                color: !isSameId && '#6FCF97' || ''
                            }}>
                                {item?.price || '-'}
                            </TableCell>

                            <TableCell
                                sx={{minWidth: '110px', ...rowStyleText, color: '#F2C94C'}}>
                                {item?.cost || '-'}
                            </TableCell>

                            <TableCell sx={{minWidth: '110px', ...rowStyleText}}>
                                {item?.rating || '-'}
                            </TableCell>

                            <TableCell sx={{minWidth: '60px', ...rowStyleText}}>
                                {item?.profit_percent || '-'}
                            </TableCell>

                            <TableCell sx={{minWidth: '60px', ...rowStyleText, padding: '0'}}>
                                <Switch
                                    disabled={isFetchingTable}
                                    sx={{

                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: (item?.is_active && item?.drying) ? '#F2C94C !important' : 'currentColor !important'
                                        }

                                    }}
                                    onChange={(e) => {
                                        handleChangeStatus((item?.symbol_id ? item?.symbol_id : item?.id), item?.is_active ? 'disable' : 'enable')
                                    }}
                                    checked={item?.is_active}
                                    defaultChecked={item?.is_active}
                                    size="medium"
                                />
                            </TableCell>


                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableBlock;