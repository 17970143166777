import React, {useState} from 'react';
import s from './styles.module.css'
import {Box, Divider, IconButton, Input, Modal, Stack, TextField} from "@mui/material";
import IconClose from "../../../../shared/assets/images/icons/iconClose";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {toast} from "react-toastify";

export const default_filter_data = {
    price: {from: '', to: ''},
    channel_width: {from: '', to: ''},
    summary_profit: {from: '', to: ''},
    price_profit: {from: '', to: ''},
    offer_profit: {from: '', to: ''},
    coupon_profit: {from: '', to: ''},
    turnover_in_money: {from: '', to: ''},
    year_coupon: {from: '', to: ''},
    nkd: {from: '', to: ''},
    nominal: {from: '', to: ''},
}

const getFieldTitle = (key: string) => {
    const titles: any = {
        price: 'Текущая цена',
        channel_width: 'Канал %',
        summary_profit: 'Доход общ.',
        price_profit: 'Доход цены',
        offer_profit: 'Доход оферт',
        coupon_profit: 'Доход куп.',
        turnover_in_money: 'Оборот',
        year_coupon: 'Размер куп.',
        nkd: 'НКД',
        nominal: 'Номинал',
    };
    return titles[key] || key;
};

export const handleChangeFilterValues = (data: any) => {
    let copy_values = {...data}
    const keys = Object.keys(copy_values)

    let haveError = false

    // for (let i = 0; i < keys?.length; i++) {
        // const value: any = copy_values[keys[i]]
        // if (value?.from && (value?.to === null || value?.to === '')) {
        //     value.to = 0
        // }
        // if (value?.to && (value?.from === null || value?.from === '')) {
        //     value.from = 0
        // }
        // if ((value?.from === 0 || value?.from === '') && (value?.to === 0 || value?.to === '')) {
        //     value.from = ''
        //     value.to = ''
        // }
        // if (!haveError) {
        //     if (+value.from > +value.to) {
        //         console.log('here')
        //         haveError = true
        //     }
        // }
    // }

    const valuesToSendFiltered = Object.keys(copy_values)?.filter((key: any) => copy_values[key].to !== '' || copy_values[key].from !== '')
    console.log(valuesToSendFiltered)
    // const valuesToSendFiltered = Object.keys(copy_values)
    // const valueToSendObj = valuesToSendFiltered?.map((key) => ({
    //     key: key,
    //     from: +copy_values[key].from,
    //     to: +copy_values[key].to
    // }))
    const valueToSendObj = valuesToSendFiltered?.map((key) => ({
        key: key,
        from: copy_values[key].from,
        to: copy_values[key].to
    }))
    let searchValue = ''
    let searchFieldsValue = ''

    for (let i = 0; i < valueToSendObj?.length; i++) {
        const fromToShowed =
            ((valueToSendObj[i]?.from && valueToSendObj[i]?.to) && `${valueToSendObj[i]?.from},${valueToSendObj[i]?.to}`) ||
            ((valueToSendObj[i]?.from && !valueToSendObj[i]?.to) && `${valueToSendObj[i]?.from}`) ||
            ((!valueToSendObj[i]?.from && valueToSendObj[i]?.to) && `${valueToSendObj[i]?.to}`)

        const fromToSeparated =
            ((valueToSendObj[i]?.from && valueToSendObj[i]?.to) && `between`) ||
            ((valueToSendObj[i]?.from && !valueToSendObj[i]?.to) && `>`) ||
            ((!valueToSendObj[i]?.from && valueToSendObj[i]?.to) && `<`)

        searchValue = searchValue.concat(`${valueToSendObj[i]?.key}:${fromToShowed};`)
        searchFieldsValue = searchFieldsValue.concat(`${valueToSendObj[i]?.key}:${fromToSeparated};`)
    }

    return [searchValue, searchFieldsValue, haveError, copy_values,valuesToSendFiltered?.length]
}

const FilterModal = ({handlerClose, open, handleData, setValues, values}: any) => {


    const handleConfirm = () => {
        const [searchValue, searchFieldsValue, haveError, copy_values] = handleChangeFilterValues(values)

        if (haveError) {
            toast.error('Ошибка выбора значений в фильтрации')
        } else {

            console.log(searchValue)
            setValues({...copy_values})
            handleData(searchValue, searchFieldsValue)
            handlerClose()
            toast.success('Фильтрация применена')
        }
    }

    const handleReset = () => {
        setValues(default_filter_data)
        handleData(null, null)
        toast.success('Фильтрация сброшена')
    }

    return (
        <Modal
            open={open}
            onClose={handlerClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{maxWidth: '920px', maxHeight: '80%'}}>
                <Stack onClick={handlerClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                    <IconClose/>
                </Stack>
                <Stack className="h2 white-90" sx={{mb: 7}}>Фильтрация</Stack>

                <Divider variant="fullWidth" sx={{mb: 7}}/>


                <Stack justifyContent="flex-start" spacing={7}
                       sx={{mt: 7, display: 'flex', height: 'calc(100% - 50px)'}}>
                    <div className={s.inputs_boxs_modal}>
                        {Object.keys(default_filter_data).map((key) => (
                            <InputRangeComponent
                                key={`${key}`}
                                title={getFieldTitle(key)}
                                value={values}
                                onChange={setValues}
                                name={key}
                            />
                        ))}
                    </div>

                    <div className={s.action}>
                        <div className={s.action_left}>
                            <Button onClick={handleConfirm}>Сохранить</Button>
                        </div>
                        <div className={s.action_right}>
                            <Button color={'warning'} onClick={handleReset}>Сбросить фильтр</Button>
                            <Button color={'neutral'} onClick={handlerClose}>Закрыть</Button>
                        </div>
                    </div>
                </Stack>

            </Box>
        </Modal>
    );
};

const InputRangeComponent = ({value, onChange, name, title}: any) => {

    return <div className={s.input_range_box}>
        <Stack className="p white-90" sx={{mb: 4}}>{title}</Stack>
        <div className={s.input_range_box_inputs}>
            <div className={s.input_range_box_inputs_inside}>
                <TextField type={'number'}
                           onChange={(e) => onChange({...value, [name]: {...value[name], from: e?.target?.value}})}
                           value={value[name].from || ''}
                           sx={{
                               width: '100%',
                               '&.MuiFormControl-root': {
                                   '&.MuiFormLabel-root': {
                                       top: '-6px !important'
                                   }
                               },
                               '&.MuiFormLabel-root': {
                                   top: '-6px !important'
                               },
                               '.MuiFormLabel-root': {
                                   top: '-6px !important'
                               },
                               '&.MuiFormHelperText-root': {
                                   bottom: '-20px !important',
                                   position: 'absolute'
                               },
                               '.MuiFormHelperText-root': {
                                   bottom: '-20px !important',
                                   position: 'absolute'
                               }
                           }} fullWidth={true} label="От" variant="outlined"
                    // helperText={Boolean(+value[name].from > +value[name].to) && `"От" меньше "До"`}
                    // error={Boolean(+value[name].from > +value[name].to)}
                />

                <TextField type={'number'} value={value[name].to || ''}
                           onChange={(e) => onChange({...value, [name]: {...value[name], to: e?.target?.value}})}
                           sx={{
                               width: '100%',
                               '&.MuiFormControl-root': {
                                   '&.MuiFormLabel-root': {
                                       top: '-6px !important'
                                   }
                               },
                               '&.MuiFormLabel-root': {
                                   top: '-6px !important'
                               },
                               '.MuiFormLabel-root': {
                                   top: '-6px !important'
                               },
                               '&.MuiFormHelperText-root': {
                                   bottom: '-20px !important',
                                   position: 'absolute'
                               },
                               '.MuiFormHelperText-root': {
                                   bottom: '-20px !important',
                                   position: 'absolute'
                               }
                           }} fullWidth={true} label="До" variant="outlined"
                    // helperText={!Boolean(+value[name].to >= +value[name].from) && `"До" меньше "От"`}
                    // error={!Boolean(+value[name].to >= +value[name].from)}
                />
            </div>
            <IconButton onClick={() => {
                onChange({
                    ...value, [name]: {
                        from: '',
                        to: '',
                    }
                })
            }}>
                <DeleteOutlineIcon color={'error'}/>
            </IconButton>
        </div>
    </div>
}

export default FilterModal;