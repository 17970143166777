import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import TradeTop from "./trade_top";
import TraderMain from "./trader_main";
import {useSearchParams} from "react-router-dom";
import {ReactComponent as Icon1} from "../../../shared/assets/images/trade_icon/1.svg";
import {ReactComponent as Icon2} from "../../../shared/assets/images/trade_icon/2.svg";
import {ReactComponent as Icon3} from "../../../shared/assets/images/trade_icon/3.svg";
import Navigate from "./navigate";
import TradeSpeculative from "./tarde_speculative";
import {DashboardContext} from "../dashboardTabs";
import PaymentBalanceModal from "../../../entities/components/modal/paymentBalanceModal";

const data = [
    {
        title: 'Основной',
        purchase: '10.000.000$',
        liquid: '10.392.212Р',
        available: '1.000Р',
        growth: '+392.212Р',
        icon: <Icon1/>,
        param: 'basic'
    },
    {
        title: 'Резервный',
        purchase: '10.000.000$',
        liquid: '10.392.212Р',
        available: '1.000Р',
        growth: '+392.212Р',
        icon: <Icon2/>,
        param: 'spare'
    },
    {
        title: 'Спекулятивный',
        purchase: '10.000.000$',
        liquid: '10.392.212Р',
        available: '1.000Р',
        growth: '+392.212Р',
        icon: <Icon3/>,
        param: 'speculative'
    },
]

const TradePage = () => {
    const [nameSpeculativeSetting, setNameSpeculativeSetting] = useState<any>({})
    console.warn(nameSpeculativeSetting)
    const [params, setParams] = useSearchParams()
    if (params.get('type') !== 'speculative') {
        params.set('type', 'speculative');
        setParams(params);
    }
    console.log(params)
    const [openPaymentBalance, setOpenPaymentBalance] = useState(false)

    const {
        dataDashboard,
    } = useContext(DashboardContext)

    const getVisibleContent = () => {
        switch (params.get('type')) {
            case 'basic': {
                return <div>basic</div>
            }
            case 'spare': {
                return <div>spare</div>
            }
            case 'speculative': {
                return <TradeSpeculative/>
            }
            default: {
                return <TraderMain data={data}/>
            }
        }
    }

    const handleBack = () => {
        params.delete('type')
        setParams(params)
    }


    const title_page = data?.find((f: any) => f.param === params.get('type'))?.title
    return (
        <div className={s.main}>
            {openPaymentBalance &&
                <PaymentBalanceModal open={openPaymentBalance} handleClose={() => setOpenPaymentBalance(false)}/>}

            <TradeTop stats={dataDashboard?.stats} setOpenPaymentBalance={() => setOpenPaymentBalance(true)}/>
            {params.get('type') && <Navigate handleBack={handleBack}
                                             title={params.get('type') === 'speculative' ? `${title_page} ${nameSpeculativeSetting.toString()?.includes('undefined ') ? '' : `(${nameSpeculativeSetting})`}` : title_page}/>}
            <TradeSpeculative setNameSpeculativeSetting={setNameSpeculativeSetting}/>
            {/*//TODO: uncomment*/}
            {/*{getVisibleContent()}*/}
        </div>
    );
};

export default TradePage;